.button {
  border-radius: 100%;
  font-family: var(--font-wide);
  font-weight: 400;
  letter-spacing: .14em;
  line-height: 1;
  padding-top: 0.9em;
  padding-bottom: 0.6em;
  
  &--primary {
    background: var(--color-white);
    color: var(--color-black);
  }
  
  &--secondary {
    background: transparent;
    color: var(--color-accent);
    border: 1px solid var(--color-accent);
  }
}
