.button-wrapper {
  position: relative;
  z-index: 1;
  margin-top: auto;
  padding: 20px 5%;
  transition: all .5s ease-in-out .5s;
  opacity: 0;
  transform: translateY(100%);
  
  .page-app--start & {
    opacity: 1;
    transform: translateY(0);
  }
}