.hashtag {
  display: grid;
  grid-template-rows: 1fr 1fr;
  color: var(--color-accent);
  margin-bottom: 1em;
  
  span {
    border-bottom: 1px solid currentColor;
    line-height: 1;
    font-size: calc(min(6vw, 28px));
    margin-bottom: .4em;
    text-align: center;
    word-spacing: calc(28vw - (calc(var(--f7-block-padding-horizontal) + var(--f7-safe-area-left) * 3)));
    
    &:first-child::before {
      content: '#';
      margin-right: 1em;
    }
  }
}
