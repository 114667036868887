.desktop-message {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 6000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 657px;
  background-color: var(--color-black);
  color: var(--color-white);

  svg {
    max-width: 100%;
  }

  &-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1120px;
    max-width: 100%;
    margin: auto;
  }

  &-intro {
    max-width: 31.875%;
  }

  &-code {
    flex-shrink: 0;
    width: 193px;
    height: 193px;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml,%3Csvg width='195' height='195' viewBox='0 0 195 195' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.7021 1H11C5.47715 1 1 5.47715 1 11V42.7021' stroke='%23707070' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M193.745 42.7021L193.745 11C193.745 5.47715 189.267 1 183.745 1L152.042 0.999998' stroke='%23707070' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M152.298 194L184 194C189.523 194 194 189.523 194 184L194 152.298' stroke='%23707070' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.00001 152.298L1 184C1 189.523 5.47715 194 11 194L42.7021 194' stroke='%23707070' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: cover;
    margin-left: 105px;
    margin-right: 65px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-txt {
    width: 36.60%;
    font-weight: 700;
  }

  &-logos {
    position: absolute;
    bottom: 40px;
    right: 45px;

    img {
      display: block;
      margin: auto;

      & + img {
        margin-top: 25px;
      }
    }

    img:last-child {
      margin-left: 18px;
      margin-top: -5px;
    }

    label {
      text-transform: uppercase;
      margin-right: 15px;
      font-size: 7px;
      letter-spacing: 1px;
      margin-top: 10px;
    }
  }
}