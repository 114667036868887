.o-link {
  color: var(--color-white);
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
  text-transform: uppercase;

  &--back {
    display: inline-flex;
    align-items: flex-start;
    text-decoration: none;
    font-size: 10px;
    
    &::before {
      content: '';
      height: 10px;
      width: 6px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.63635 1L0.99999 5L4.63635 9' stroke='%23C9CACC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      margin-right: 5px;
    }
  }
}