.text-wide {
  font-family: var(--font-wide);
}

.text-extawide {
  font-family: var(--font-extrawide);
}

.text-spaced {
  letter-spacing: .1em;
}

.text-underline {
  text-decoration: underline;
}

.text-upper {
  text-transform: uppercase;
}