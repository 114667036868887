.about {
  &-title {
    font-size: clamp(14px, 4vw, 20px);
  }

  &-text {
    font-size: clamp(14px, 4vw, 20px);
  }

  &-logos {
    margin-top: 30px;
    
    img:last-child {
      margin-left: 18px;
      margin-top: -5px;
    }
  
    label {
      text-transform: uppercase;
      margin-right: 15px;
      font-size: 7px;
      letter-spacing: 1px;
      margin-top: 10px;
    }
  }
}
