@keyframes scan {
  0% {
    // opacity: .5;
    top: 5%;
  }  
  // 16.6% {
  //   // opacity: 1;
  //   top: 35%;
  // }
  // 33.2% {
  //   // opacity: .5;
  //   top: 65%;
  // }
  50% {
    // opacity: 1;
    top: 95%;
  }
}