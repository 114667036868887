.sheet-modal {
  background-color: transparent;
  
  &-bottom::before,
  &:not(.sheet-modal-top):before {
    display: none;
  }

  &-inner {
    background-color: var(--color-white);
    border-radius: 20px 20px 0 0;
  }

  .toolbar {
    background: transparent;
    backdrop-filter: none;

    &-inner {
      justify-content: center;
    }

    .link {
      color: var(--color-white);
    }
  }
}

