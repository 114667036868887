.scan-area {
  position: relative;
  z-index: 1;
  width: 80vw;
  max-width: 45vh;
  height: 0;
  // aspect-ratio: 7/10;
  background: url("data:image/svg+xml,%3Csvg width='241' height='345' viewBox='0 0 241 345' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M42.8298 1H11.1277C5.6048 1 1.12766 5.47715 1.12766 11V42.7021' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M239.872 42.7021L239.872 11C239.872 5.47715 235.395 1 229.872 1L198.17 0.999998' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M198.17 344L229.872 344C235.395 344 239.872 339.523 239.872 334L239.872 302.298' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.12766 302.298L1.12766 334C1.12766 339.523 5.60481 344 11.1277 344L42.8298 344' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat center / contain;
  pointer-events: none;
  margin: auto;
  opacity: 0;
  transition: all .5s ease-in-out;

  .page-app--start & {
    height: 114vw;
    max-height: 65vh;
    opacity: 1;
  }
  
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    display: block;
    top: 5%;
    height: 1px;
    background-color: red;
    border-radius: 100%;
    box-shadow: 0 0 5px 1px red;
    opacity: 0;

    .is-scanning & {
      animation: scan 4s linear infinite;
      opacity: 1;
      transition: opacity .2s ease-in-out;
    }
  }

  &::before {
    content: 'Encaixe o livro dentro das \A marcações, depois toque no \A botão para escanear.';
    white-space: pre;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    color: var(--color-white);
    font-family: var(--font-wide);
    font-size: clamp(10px, 2vw, 16px);
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-left: 5%;
    padding-right: 5%;
    pointer-events: none;
    text-transform: uppercase;
    transition: opacity .3s ease-in-out .5s;
    transform: translateX(-50%);

    .page-app--start & {
      opacity: 1;
    }

    .with-modal-sheet & {
      content: '';
      opacity: 0;
    }
    
    .is-scanning & {
      content: 'escaneando...';
      position: absolute;
      top: 100%;
      display: block;
      width: 100%;
    }
  }

}