.intro {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  font-size: clamp(10px, 3vw, 14px);
  text-align: center;
  padding-top: 102px;

  &-instruction {
    font-size: clamp(11px, 3.1vw, 15px);
    font-weight: 700;
    
    &--small {
      font-size: clamp(10px, 3vw, 14px);
      font-weight: 700;
    }
  }
  
}