.landscape-message {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 6000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
  color: var(--color-white);
  
  @media (orientation: portrait) {
    display: none;
  }

  &::before {
    content: '';
    height: 87px;
    width: 86px;
    background-image: url("data:image/svg+xml,%3Csvg width='86' height='87' viewBox='0 0 86 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.999992' y='85.9473' width='45' height='84' rx='4' transform='rotate(-90 0.999992 85.9473)' fill='%23050505' stroke='%236D6E71' stroke-width='2'/%3E%3Cpath d='M7.49999 72.9473L7.49999 54.9473' stroke='%236D6E71' stroke-linecap='round'/%3E%3Crect x='40' y='1.94727' width='45' height='84' rx='4' fill='%23050505' stroke='white' stroke-width='2'/%3E%3Ccircle cx='62' cy='78.9473' r='2' fill='%23D9D9D9'/%3E%3Cpath d='M53 8.44727H71' stroke='white' stroke-linecap='round'/%3E%3Cpath d='M30.5431 14.9766C30.7384 14.7813 30.7384 14.4648 30.5431 14.2695L27.3611 11.0875C27.1659 10.8922 26.8493 10.8922 26.654 11.0875C26.4588 11.2828 26.4588 11.5994 26.654 11.7946L29.4825 14.623L26.654 17.4515C26.4588 17.6467 26.4588 17.9633 26.654 18.1586C26.8493 18.3538 27.1659 18.3538 27.3611 18.1586L30.5431 14.9766ZM26.1898 15.123L30.1896 15.123L30.1896 14.123L26.1898 14.123L26.1898 15.123ZM9.18971 32.1231C9.18971 22.7342 16.8009 15.123 26.1898 15.123L26.1898 14.123C16.2486 14.123 8.18971 22.182 8.18971 32.1231L9.18971 32.1231Z' fill='white'/%3E%3C/svg%3E");
  }
  
  &-txt {
    font-size: clamp(11px, 2vw, 15px);
    font-weight: 700;
    margin-top: 2em;
  }
}

