.navbar {
  height: 102px;
  position: absolute;
  top: 0;
  
  &,
  a {
    color: var(--color-black);
    
    &.link {
      height: auto;
      line-height: normal;
      padding: 0 20px 0 0;
    }
  }
  
  &-inner {
    align-items: flex-start;
    padding: 20px 0 0;
  }

  &-text {
    font-size: 10px;
    font-weight: 700;
    margin-right: 1em;
    padding-top: .4em;
  }

  &--home {
    &,
    a {
      color: var(--color-white);
    }

    &.navbar-transparent:not(.navbar-large):not(.navbar-transparent-visible):not(.with-searchbar-expandable-enabled) .navbar-bg {
      opacity: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      backdrop-filter: none;
    }
  }

  &--result {
    .c-logo {
      color: var(--color-accent);
    }
    
    &,
    a {
      color: var(--color-white);
    }
  }
}