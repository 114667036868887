.page-app {
  .page-content {
    padding-top: 102px;
  }

  &--start .intro {
    display: none;
  }

  &--result {
    background-color: var(--color-black);
    color: var(--color-white);
  }
}
