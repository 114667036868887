.ios,
.md,
.aurora {
  --f7-font-family: var(--font-default);
}

::selection {
  color: var(--color-black);
  background: var(--color-accent);
}

h2 {
  font-size: 18px;
}

strong {
  font-weight: 600;
}

canvas,
video {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
}

canvas {
  border: none;
}

video {
  background-color: var(--color-black);
}

hr {
  border: none;
  border-top: 1px solid var(--color-accent);
  margin-top: 2em;
  margin-bottom: 2em;
}